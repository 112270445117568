:root {
  --greenBackground: #eafff2;
  --green: #4ece21;
  --lightGreen: #bbf885;
  --orange: #ff794e;
  --darkGreenText: #224422;
  --purple: #8c21ce;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.isFlexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.greenButton {
  border-color: #92e3c3;
  background-color: #f1fff9;
  border-radius: 40px;
  width: 40%;
}

.orangeButton {
  border-color: #fb8007;
  background-color: #f1fff9;
  border-radius: 40px;
  width: 40%;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

.navLogo {
  width: 9rem;
  object-fit: cover;
}

.navbar .txtLink {
  font-weight: 700;
  color: #374039;
  padding-left: 2rem;
  padding-right: 2rem;
}

.navbar-item img {
  max-height: max-content;
}

.navbar {
  min-height: 4rem;
  max-width: 1334px;
  margin: 0 auto;
}

.navbar .custom-icon {
  height: 1.1rem;
}

.navbar .navbar-item .numberOfProducts {
  font-size: 0.8rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  background-color: transparent;
  color: #6a746c;
}

.navbar .navbar-burger {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.navbar .navbar-end .spanText {
  padding-left: 0.5rem;
}

.navbar .navbar-end .account {
  padding-left: 1.7rem;
}

#homeWelcomeSection {
  background-color: var(--greenBackground);
  min-height: 90vh;
}

#homeWelcomeSection .basket-image {
  position: absolute;
  right: 2rem;
  width: 70%;
  top: 1.5rem;
  border-radius: 30px;
}

#homeWelcomeSection .container {
  height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#homeWelcomeSection .left {
  position: relative;
  background-color: white;
  width: 36%;
  border-radius: 30px;
  padding: 3rem;
  border: 3px solid var(--lightGreen);
}

#homeWelcomeSection p {
  margin-bottom: 2rem;
  color: var(--darkGreenText);
}

#homeWelcomeSection .title {
  color: var(--orange);
  font-family: 'Peace Sans';
  font-size: 2.2rem;
}

#homeWelcomeSection .buttons {
  display: flex;
  justify-content: space-between;
}

#homeWelcomeSection .buttons a {
  margin: 2px 0;
}

#homeWelcomeSection .reviews {
  margin-top: 1.5rem;
  margin-left: 3rem;
}

#homeWelcomeSection .reviews img {
  height: 2.5rem;
  margin-left: 1rem;
}

#zastoKorpaSection {
  background-color: #f3fdf7;
}

#zastoKorpaSection .subtitleDiv {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

#zastoKorpaSection h2 {
  color: var(--darkGreenText);
  font-family: 'Peace Sans';
  font-size: 2.2rem;
  padding: 0.3rem 0.5rem;
  background-color: white;
  border-radius: 20px;
}

#zastoKorpaSection img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  display: block;
  margin: auto;
}

#zastoKorpaSection .column div {
  background-color: white;
  border-radius: 75px;
  padding: 3rem;
  margin: 1rem;
}

#zastoKorpaSection .column {
  display: flex;
}

#zastoKorpaSection .column div p {
  line-height: 28px;
  color: #224422;
}

#zastoKorpaSection .shop-button {
  display: flex;
  justify-content: center;
}

#kakoFunkcionisuNarudzbe .subtitleDiv {
  text-align: center;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

#kakoFunkcionisuNarudzbe h2 {
  color: #3a2229;
  font-family: 'Peace Sans';
  font-size: 2.2rem;
  padding: 0.3rem 0.5rem;
  background-color: white;
  border-radius: 20px;
}

#kakoFunkcionisuNarudzbe h3 {
  color: #3a2229;
  font-family: 'Peace Sans';
  font-size: 1.7rem;
  padding: 0.3rem 0.5rem;
  background-color: white;
}

#kakoFunkcionisuNarudzbe .container {
  background-color: #ffeee5;
  border-radius: 30px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#kakoFunkcionisuNarudzbe .column div {
  padding: 2rem;
}

#kakoFunkcionisuNarudzbe .column div p {
  color: #3a2229;
  line-height: 30px;
}

#kakoFunkcionisuNarudzbe .shop-button {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.narudzbe-tabs {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.narudzbe-tab-list {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.narudzbe-tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 1rem;
  width: 20%;
  color: #3a2229;
  text-align: center;
  border: 1px solid #3a2229;
  font-weight: 700;
}

.narudzbe-tab-list-item:hover {
  cursor: pointer;
}

.narudzbe-tab-list-item:first-of-type {
  border-top-left-radius: 20px;
}

.narudzbe-tab-list-item:last-of-type {
  border-top-right-radius: 20px;
}

.narudzbe-tab-list-active {
  background-color: white;
  border: 0px;
}

.narudzbe-tab-content {
  background-color: white;
  width: 60%;
  margin: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.narudzbe-tab-content p {
  color: #4d5e50;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid rgba(82, 60, 65, 0.1);
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.narudzbe-tab-content p:first-of-type {
  padding-top: 1.5rem;
}

.narudzbe-tab-content p:last-of-type {
  padding-bottom: 1.5rem;
  border-bottom: 0px;
}

#nasaPrica h3 {
  font-family: 'Peace Sans';
  font-weight: normal;
  font-size: 2rem;
  text-transform: uppercase;
  color: #35453a;
  background-color: #f3fdf7;
  padding: 0rem 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

#nasaPrica img {
  border-radius: 20px;
}

#nasaPrica p {
  font-weight: 600;
  font-size: 17px;
  line-height: 34px;
  color: #4f4f4f;
}

#nasaPrica .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

#nasaPrica .columns:last-of-type {
  background-color: #f3fdf7;
  margin-top: 2rem;
  border-radius: 20px;
}

#nasaPrica .columns:last-of-type h3 {
  background-color: white;
}

.productComponent {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
  border-radius: 15px;
  position: absolute;
  padding-bottom: 6.5rem;
  height: 28rem;
  left: 0.75rem;
  right: 0.75rem;
}

.productComponent .productDesc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.underlined-orange-button:hover {
  color: white;
}

.underlined-green-button:hover {
  color: white;
}

.underlined-green-button {
  background: var(--green);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgb(58 148 50 / 25%);
  border-radius: 10px;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.purple-button {
  background: var(--purple);
}

.transparent-underlined-button {
  background: #ffffff;
  border: 1px solid #72ce21;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(58, 148, 50, 0.15);
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--darkGreenText);
}

.underlined-orange-button {
  background: var(--orange);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgb(58 148 50 / 25%);
  border-radius: 10px;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.underlined-green-button span,
.underlined-orange-button span,
.transparent-underlined-button span {
  border-bottom: 1px solid white;
  padding-bottom: 1px;
}

.finish-order-button {
  width: 40%;
  text-align: center;
}

.slide-1 {
  background-image: url('./images/korpa_logo_bez_teksta.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  position: relative;
}

#_headline {
  height: 100%;
  padding: 0;
  position: relative;
  width: 100%;
}

#_headline .container.is-fluid {
  margin-left: 0;
  margin-right: 0;
}

#_headline .swiper-container {
  height: 80vh;
  width: 100%;
}

#homePagePhoto {
  background: linear-gradient(rgba(25, 35, 62, 0.7), rgba(25, 35, 62, 0.8)),
    url(./images/korpa-fresh.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#homePagePhoto .container {
  text-align: center;
}

#homePagePhoto .container h1 {
  font-family: 'Cavinanar', cursive;
  color: #76cc29;
  letter-spacing: 3px;
  font-size: 3.2rem;
}

#homePagePhoto .container h2 {
  color: white;
  font-size: 2.2rem;
  margin-top: 1px;
}

#homePagePhoto .container .button {
  color: white;
  background: linear-gradient(to bottom right, #76cc29, #7cc639);
  width: 10rem;
}

#navigacija .navbar-brand {
  display: none;
}

#navigacija {
  width: 100%;
  margin-top: 1em;
  z-index: 1000;
  background: transparent;
  position: absolute;
}

#navigacija .navbar-menu .navbar-start {
  margin: 0px auto;
}

#navigacija .navbar-menu a {
  display: inline-block;
  letter-spacing: 2px;
  color: white;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  margin: 1rem;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}

#navigacija .navbar-menu a:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -6px;
  left: 0;
  height: 2px;
  background: #76cc29;
  opacity: 0;
  transform: translateY(100%);
  transition: ease-in-out all 0.3s;
}

#navigacija .navbar-menu a:hover {
  color: #76cc29;
}
#navigacija .navbar-menu a:hover:before {
  opacity: 1;
  transform: translateY(-500%);
}

#o_nama {
  text-align: center;
  background-color: #ebe0fb;
}

#o_nama .container p {
  line-height: 2em;
  font-size: 18px;
  letter-spacing: 1px;
  color: black;
}

#o_nama .container .slike {
  margin: 2rem 0;
}

#o_nama .container .slike .column {
  padding: 2rem;
}

#o_nama .container .slike .column img {
  height: 320px;
  object-fit: cover;
  border-radius: 100%;
}

#o_nama .container .slike .column h3 {
  font-weight: 600;
  padding-top: 2rem;
  word-spacing: 1px;
  font-size: 1.1rem;
}

#o_nama .container .button {
  padding: 0 4rem;
  border: 0;
  color: white;
  height: 3.3rem;
  margin-bottom: 2rem;
  background-color: #76cc29;
}

#informacije_1 {
  background-image: url(./images//povrce.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;
}

#informacije_1 .container .columns .column {
  background: linear-gradient(rgba(25, 35, 62, 0.7), rgba(25, 35, 62, 0.8));
  text-align: center;
  color: #76cc29;
  font-size: 1.2rem;
  padding: 3rem 2rem;
}

#informacije_1 .container {
  margin: 0;
  max-width: 100%;
}

#informacije_1 .container p {
  line-height: 2rem;
}

#w_footer {
  background-color: #eafff2;
}

#w_footer p a {
  font-weight: normal;
  font-size: 0.8rem;
  text-align: center;
  color: #000000;
}

#w_footer .footer_icon {
  color: #000000;
  font-size: 1.1rem;
  height: 1.2rem;
  width: 1.2rem;
  object-fit: contain;
}

.header-background {
  background: linear-gradient(rgba(25, 35, 62, 0.7), rgba(25, 35, 62, 0.8)),
    url(./images/korpa-fresh.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 39%;
  height: 14rem;
}

.header-background h1 {
  position: relative;
  text-align: center;
  padding-top: 8rem;
  color: white;
  font-weight: 500;
}

.categorySearchWrapper {
  background-color: white;
  z-index: 100;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.categorySearchWrapper .input {
  border-radius: 30px;
  border-color: #92e3c3;
}

.categorySearchWrapper .closeIcon {
  pointer-events: initial !important;
  cursor: pointer;
}

.categoriesList {
  position: absolute;
  background: linear-gradient(to right, #19233e, #293d72);
  color: white;
  z-index: 100;
  left: 1.8rem;
  border-radius: 15px;
  padding-bottom: 1.5rem;
  max-height: 30rem;
  overflow: auto;
}

.categoriesList div {
  padding-left: 2rem;
  padding-right: 5rem;
  padding-top: 1rem;
  cursor: pointer;
}

.categoryDropdown {
  margin: 0.5rem 1.8rem;
  margin-left: 0;
  width: auto;
}

.carretIcon {
  font-size: 1.1rem;
  margin-left: 8px;
}

.slikaProizvoda {
  height: 160px !important;
  object-fit: cover;
  border-radius: 15px;
}

#ponuda_proizvodi .column {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  padding-bottom: 28rem;
  padding-top: 3rem;
}

#ponuda_proizvodi {
  padding-top: 1rem;
}

#ponuda_proizvodi .category-title {
  font-weight: 700;
  color: #374039;
  margin-top: 2.5rem;
  margin-bottom: -1.5rem;
  font-size: 1.15rem;
}

#favoritiSection .column {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  padding-bottom: 26rem;
  padding-top: 3rem;
}

#favoritiSection {
  padding-top: 1rem;
}

#favoritiSection .subtitleDiv h3 {
  font-family: 'Peace Sans';
  font-weight: normal;
  font-size: 1.5rem;
  color: #1a2d22;
  background-color: white;
  padding: 0 1rem;
  margin-top: 4rem;
}

.productName {
  margin-top: 0.8rem;
  margin-bottom: 0.3rem;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.uKorpuButton {
  border-color: #92e3c3;
  background-color: #f1fff9;
  flex: 6;
  border-radius: 40px;
  margin-top: 1rem;
  margin-right: 1.5rem;
}

.mjeraCijenaProizvoda {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

#ponuda_proizvodi .button:focus {
  outline: none;
  box-shadow: none;
}

.numericInputWrapper {
  display: flex;
  border: 1px solid #92e3c3;
  border-radius: 30px;
}

.numericInputWrapper input {
  flex: 6;
  border-radius: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  height: 1.8em;
  padding-bottom: 0;
  padding-top: 0;
}

.numericInputWrapper button {
  border-width: 0;
  background-color: transparent;
  flex: 3;
  height: 1.8em;
  padding-bottom: 0;
  padding-top: 0;
}

.numericInputWrapper button:focus {
  outline: none;
  box-shadow: none;
}

.numericInputWrapper button span {
  font-size: 0.6rem;
}

.dodajUKorpuDiv {
  display: flex;
  align-items: baseline;
}

.numericInputAddToCartWrapper {
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;
  right: 0.75rem;
}

.proizvodLink {
  cursor: pointer;
}

.opisProizvoda {
  min-height: 3rem;
  font-weight: 200;
  font-size: 0.95rem;
}

.heartIconSpan {
  color: #fb8007;
  font-size: 1.7rem;
  width: 40px;
  height: 40px;
  text-align: center;
}

.heartIconSpan:hover {
  cursor: pointer;
}

.headerLinkShoppingBasket {
  margin-left: 0.5rem;
}

.korpaPeakIcon {
  position: fixed;
  width: 1.5rem;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 50vh;
}

.leftChevronIcon {
  font-size: 1.3rem;
  background-color: #19233e;
  color: white;
  padding: 6px;
  cursor: pointer;
}

.cartPeakWrapper {
  position: fixed;
  background-color: #19233e;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30rem;
  z-index: 1500;
  padding: 3rem 2rem;
  overflow: auto;
}

.cartPeakWrapper .productDiv {
  border-bottom: 1px solid dimgray;
  padding: 1rem 0.5rem;
}

.cartPeakWrapper .productDiv button {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #fb8007;
  padding: 2px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
}

.cartPeakWrapper .praznaKorpaPoruka {
  color: white;
  width: 100%;
  margin-top: 20rem;
  text-align: center;
}

.cartPeakWrapper .totalPriceDiv {
  color: white;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid dimgray;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.cartPeakWrapper .buttonsDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.cartPeakWrapper .button {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2rem;
}

.cartPeakWrapper .button:focus {
  outline: none;
  box-shadow: none;
}

.cartPeakWrapper p {
  color: white;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.cartPeakWrapper .nazivProizvoda {
  color: #fb8007;
  font-size: 1rem;
}

.cartPeakWrapper .closeCartPeakIcon {
  position: absolute;
  top: 1rem;
  right: 2rem;
  color: #fb8007;
  font-size: 1.4rem;
  cursor: pointer;
}

.successMessage {
  position: absolute;
  top: 0;
  background-color: #76cc29;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  padding: 5px 10px;
}

.successMessage p {
  color: white;
  text-align: center;
  font-size: 1.1rem;
}

.errorMessage {
  position: absolute;
  top: 0;
  background-color: #f33950;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  padding: 5px 10px;
}

.errorMessage p {
  color: white;
  text-align: center;
  font-size: 1.1rem;
}

#moja_korpa {
  padding-top: 2rem;
}

#moja_korpa .productDetail {
  padding: 1rem;
}

#moja_korpa .productDetail img {
  height: 130px !important;
  object-fit: cover;
  border-radius: 15px;
}

#moja_korpa .productDetail .name {
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #fb8007;
  font-weight: bold;
}

#moja_korpa .productColumn {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  padding-bottom: 25rem;
  padding-top: 3rem;
}

#moja_korpa .productComponent {
  height: 25rem;
}

#moja_korpa .removeProductWrapper {
  display: flex;
  justify-content: space-between;
}

#moja_korpa .removeProductWrapper button {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #fb8007;
  padding: 2px;
  font-size: 1rem;
  cursor: pointer;
}

#moja_korpa .removeProductWrapper button:focus {
  outline: none;
  box-shadow: none;
}

#moja_korpa .mojaKorpaDataWrapper {
  background-color: #19233e;
  padding: 2rem;
  padding-top: 1.5rem;
  border-radius: 15px;
  margin-top: 2rem;
}

#moja_korpa .mojaKorpaDataWrapper .proizvodi {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  font-size: 0.9rem;
}

#moja_korpa .mojaKorpaDataWrapper .button {
  margin-bottom: 1.5rem;
}

#moja_korpa .mojaKorpaDataWrapper .total {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-top: 1rem;
  padding: 1rem 0;
  color: white;
  letter-spacing: 1px;
}

#moja_korpa .mojaKorpaDataWrapper .minimalnaCijena {
  margin-top: 1rem;
  color: #fb8007;
}

#moja_korpa .emptyCartDiv {
  text-align: center;
  margin-top: 2rem;
  line-height: 1.6rem;
}

#korisnik .korisnikForma,
#korisnik .korisnikUpdateForma {
  width: 70%;
  margin: 0px auto;
  margin-top: 1rem;
}

#korisnik .korisnikForma {
  width: 40%;
}

#korisnik .korisnikForma label {
  font-size: 0.9rem;
}

#korisnik .korisnikUpdateForma button {
  margin-top: 2.5rem;
}

#korisnik .korisnikUpdateForma button:focus {
  outline: none;
  box-shadow: none;
}

#korisnik .korisnikUpdateForma select {
  border-radius: 30px;
}

#korisnik .buttonsContainer {
  display: flex;
  justify-content: space-between;
}

#korisnik input {
  border-radius: 30px;
}

#korisnik button {
  margin-top: 1rem;
}

#korisnik .korisnikForma .linkZaRegistraciju {
  margin-top: 1.5rem;
}

#korisnik .forgotPasswordLink {
  text-align: right;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

#korisnik .korisnikUpdateForma .field-body .field {
  flex: 1;
}

#korisnik .verificationWarning {
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.4rem;
}

#korisnik .verificationWarning p {
  color: #f33950;
}

#korisnik .verificationWarning button {
  border-color: #92e3c3;
  border-radius: 30px;
}

.fullScreenLoader {
  z-index: 1100;
}

.fullScreenLoader .modal-content {
  text-align: center;
}

.fullScreenLoader p {
  margin-top: 2rem;
  color: white;
}

.errorNotification {
  background-color: #f33950;
  color: white;
  border-radius: 30px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
}

.errorNotification span {
  flex: 1;
  text-align: center;
  align-self: center;
}

.errorNotification p {
  flex: 11;
}

.successNotification {
  background-color: #6caf94;
  color: white;
  border-radius: 30px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
}

.successNotification span {
  flex: 1;
  text-align: center;
  align-self: center;
}

.successNotification p {
  flex: 11;
}

#checkout .checkoutForma,
#checkout .orderConfirmation {
  width: 40%;
  margin: 0px auto;
  margin-top: 1rem;
}

#checkout .checkout-data-div p {
  margin-top: 4px;
  margin-bottom: 4px;
}

#checkout .brojStavki {
  color: #6caf94;
}

#checkout .last-month-expense-info {
  color: gray;
  font-size: 14px;
}

#checkout .korisnikData {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  margin: 1rem 0;
}

#checkout .korisnikData p {
  line-height: 1.7rem;
}

#checkout .checkoutForma .korisnikData p:first-child {
  font-weight: bold;
}

#checkout .checkoutForma button {
  margin-top: 1.5rem;
}

#checkout .info-icon {
  color: orange;
  font-size: 20px;
  margin-left: 4px;
  cursor: pointer;
}

#checkout .discount-modal .modal-content {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
}

#checkout .discount-modal .modal-content h2 {
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

#checkout .donacija {
  border-top: 1px solid lightgrey;
  padding-top: 1rem;
}

#moje_narudzbe .detalji {
  padding: 1rem 0;
}

#moje_narudzbe .detalji p {
  line-height: 1.8rem;
}

#moje_narudzbe .detalji .medium_text {
  font-weight: 600;
}

#moje_narudzbe .detalji .total {
  color: var(--orange);
  font-weight: bold;
  font-size: 17px;
}

#moje_narudzbe .detalji table {
  border-top: 2px solid lightgray;
  margin-top: 6px;
}

#moje_narudzbe .subtitle {
  margin-bottom: 0rem;
}

.spinnerWrapper {
  text-align: center;
}

.spinnerWrapper p {
  margin-top: 1rem;
}

.productModal {
  z-index: 1100;
}

.productModal .modal-card-foot {
  justify-content: space-between;
}

.productModal .cijena {
  color: #fb8007;
  font-weight: 600;
}

#_registracija .registrationForm {
  width: 70%;
  margin: 0px auto;
  margin-top: 1rem;
}

#_registracija .registrationForm .input {
  border-radius: 30px;
}

#_registracija .registrationForm .field-body .field {
  flex: 1;
}

#_registracija .registrationForm .field-body .field label {
  font-size: 0.9rem;
}

#_registracija .registrationForm .button {
  margin-top: 2rem;
}

#_registracija .registrationForm select {
  border-radius: 30px;
}

#_registracija .registerErrorSpan {
  color: #f33950;
  font-size: 0.9rem;
  margin-top: 3px;
  padding-left: 0.5rem;
}

#_forgotPasswordPage .input {
  border-radius: 30px;
}

#_forgotPasswordPage .forgotPasswordForm {
  width: 40%;
  margin: auto;
  margin-top: 2rem;
}

#_resetPasswordPage .resetPasswordForm {
  width: 40%;
  margin: auto;
  margin-top: 2rem;
}

#_resetPasswordPage .input {
  border-radius: 30px;
}

.iconDiv {
  text-align: center;
  width: 100%;
}

.checkedCircleIcon {
  font-size: 4rem;
  color: #76cc29;
}

.storeLinksDiv {
  display: none;
}

/********************************************************************/
/********************************** Mobile **********************************/

@media only screen and (max-width: 769px) {
  .navbar {
    min-height: 3.25rem;
  }

  .navLogo {
    width: 8rem;
  }

  .underlined-green-button,
  .underlined-orange-button,
  .transparent-underlined-button {
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
  }

  .navbar-start {
    margin-left: 0rem;
  }

  .navbar .navbar-start a {
    font-size: 1.2rem;
  }

  .slikaProizvoda {
    height: 200px !important;
  }

  #homeWelcomeSection .left {
    width: 100%;
    padding: 2rem;
  }

  #homeWelcomeSection .basket-image {
    display: none;
  }

  #homeWelcomeSection .reviews {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }

  #homeWelcomeSection .title {
    font-size: 1.8rem;
  }

  #zastoKorpaSection h2 {
    font-size: 1.8rem;
  }

  #zastoKorpaSection .column div {
    padding: 2rem;
    margin: 0rem;
  }

  .narudzbe-tab-list-item {
    display: block;
    width: 80%;
    margin: auto;
  }

  .narudzbe-tab-list {
    display: block;
  }

  .narudzbe-tab-content {
    width: 80%;
  }

  .narudzbe-tab-content p {
    padding: 0.5rem 2rem;
    display: block;
  }

  .narudzbe-tab-list-item:first-of-type {
    border-top-right-radius: 20px;
  }

  .narudzbe-tab-list-item:last-of-type {
    border-radius: 0px;
  }

  #kakoFunkcionisuNarudzbe .column div {
    padding: 1rem;
    text-align: center;
  }

  #kakoFunkcionisuNarudzbe .column div img {
    width: 80%;
  }

  #kakoFunkcionisuNarudzbe h2 {
    font-size: 2em;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  #kakoFunkcionisuNarudzbe h3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  #zastoKorpaSection .shop-button,
  #kakoFunkcionisuNarudzbe .shop-button {
    width: 80%;
    margin: auto;
  }

  #poznatoIz .images {
    display: block;
    justify-content: space-evenly;
  }

  #poznatoIz .image-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  #nasaPrica .column {
    padding: 1.5rem;
  }

  #navigacija .navbar-brand {
    display: flex !important;
    width: 100%;
    margin-top: 0.7rem;
  }

  #navigacija .navbar-menu a:before {
    content: none;
  }

  #navigacija {
    background: linear-gradient(rgba(25, 35, 62, 0.4), rgba(25, 35, 62, 0.5));
    margin-top: 0;
    z-index: 1000;
    height: 5rem;
  }

  #navigacija .navbar-brand .navbar-item {
    color: white;
    font-size: 1.2rem;
  }

  #navigacija .navbar-brand .navbar-burger {
    margin-top: 7px;
    color: white;
  }

  #homePagePhoto .container h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 4rem;
  }

  #homePagePhoto .container h2 {
    line-height: 2.5rem;
    font-size: 1.9rem;
  }

  #navigacija .navbar-menu {
    width: 100%;
    background: linear-gradient(#19233e, #19233e);
  }

  #navigacija .navbar-menu .navbar-start .navbar-item {
    width: 100%;
    font-size: 1rem;
  }

  #o_nama .container .slike .column img {
    height: 250px;
  }

  #ponuda_proizvodi .column:first-child {
    padding-top: 1.2rem;
  }

  .categoryDropdown {
    margin-left: 5px;
  }

  .categoriesList {
    left: 1rem;
    max-height: 20rem;
    overflow-y: scroll;
  }

  .cartPeakWrapper {
    width: 100%;
  }

  #moja_korpa .productColumn {
    margin-top: 0rem;
  }

  #checkout .checkoutForma,
  #checkout .orderConfirmation {
    width: 90%;
    margin-top: 0;
  }

  #korisnik .korisnikForma,
  #korisnik .korisnikUpdateForma {
    width: 90%;
    margin-top: 0;
  }
  .categorySearchWrapper {
    display: block;
    padding-top: 0rem;
  }

  .categorySearchWrapper .field {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0.5rem;
  }

  #_registracija .registrationForm {
    width: 100%;
  }

  #_forgotPasswordPage .forgotPasswordForm,
  #_resetPasswordPage .resetPasswordForm {
    width: 100%;
  }

  .productModal .modal-card {
    margin: 2rem;
    width: 90%;
  }

  #korisnik .korisnikUpdateForma button {
    width: 100%;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .storeLinksDiv {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background: black;
    display: flex;
    align-items: flex-end;
    padding: 0 15px;
  }

  .storeLinksDiv img {
    width: 45%;
    margin: 20px 4px;
    height: 50px;
  }

  .storeLinksDiv span {
    position: absolute;
    top: -10px;
    right: 10px;
    background-color: black;
    width: 26px;
    height: 26px;
    border-radius: 20px;
    color: white;
    font-size: 18px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
  }
}
/* End of mobile */

/********************************************************************/
/********************************** Min 769 - Max 1024 **********************************/

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #navigacija .navbar-brand {
    display: flex !important;
    width: 100%;
    margin-top: 0.7rem;
  }

  #navigacija .navbar-menu a:before {
    content: none;
  }

  #navigacija {
    background: linear-gradient(rgba(25, 35, 62, 0.4), rgba(25, 35, 62, 0.5));
    margin-top: 0;
    z-index: 1000;
    height: 5rem;
  }

  #navigacija .navbar-brand .navbar-item {
    color: white;
    font-size: 1.2rem;
  }

  #navigacija .navbar-brand .navbar-burger {
    margin-top: 7px;
    color: white;
  }
  #navigacija .navbar-menu {
    width: 100%;
    background: linear-gradient(#19233e, #19233e);
  }

  #navigacija .navbar-menu .navbar-start .navbar-item {
    width: 100%;
    font-size: 1rem;
  }

  .navbar .navbar-start {
    margin-left: 0;
  }
}

/********************************************************************/
/********************************** Min 1024 **********************************/

@media screen and (min-width: 1024px) {
  .navbar {
    max-width: 960px;
  }
}

@media screen and (min-width: 1216px) {
  .navbar {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .navbar {
    max-width: 1344px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
